import { ReactElement } from 'react';

import {
  AuthenticationTab,
  Client,
  FlowMethod,
  FlowStrategy,
  IconType,
  InputType,
  NodeAction,
  NodeGroup,
  ServerMessageType,
} from '../enum';
import { CARD_SETTINGS } from '../pages/Settings/components/UserAccess/UserAccess';
import { HintsFn } from '../components';

export namespace Maps {
  export const TOOLTIP_MESSAGE_MAP = new Map<
    Client,
    string | (() => ReactElement)
  >([
    [
      Client.Identifier,
      'Username must be at least 4 characters long and alphabetic letters should be only lowercase. Can also contain numbers and special symbols: ".", "@"',
    ],
    [Client.NationalId, 'NRC'],
    [
      Client.WebAuthRegisterDisplayName,
      'Security keys are, as described by the FIDO Alliance, "a password replacement that provides faster, easier, and more secure sign-ins to websites and apps across a user\'s devices."',
    ],
    [
      Client.Password,
      () =>
        HintsFn(
          CARD_SETTINGS.find((s) => s.flowStrategy === FlowStrategy.Password)
            ?.listHints ?? [],
        ),
    ],
  ]);
  export const INPUT_ICON_MAP = new Map([
    [IconType.Identifier, 'icon-dgpass-user'],
    [IconType.Password, 'icon-dgpass-password'],
    [IconType.Key, 'icon-dgpass-key'],
    [IconType.WebAuthDisplayName, 'icon-dgpass-key'],
  ]);

  export const FORM_FLOW_MAP = new Map([
    [
      FlowStrategy.SignIn,
      [
        NodeGroup.Default,
        NodeGroup.Password,
        NodeGroup.Totp,
        NodeGroup.Mfa,
        NodeGroup.LookupSecret,
        NodeGroup.Passkey,
      ],
    ],
    [
      FlowStrategy.SignInWithOauth2,
      [
        NodeGroup.Default,
        NodeGroup.Webauthn,
        NodeGroup.Password,
        NodeGroup.Totp,
        NodeGroup.Mfa,
        NodeGroup.LookupSecret,
      ],
    ],
    [FlowStrategy.SignInSecurityKey, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.SignInQRCode, [NodeGroup.QRCode]],
    [FlowStrategy.SignInPasskey, [NodeGroup.Passkey]],
    [FlowStrategy.SignUp, [NodeGroup.Default, NodeGroup.Password]],
    [FlowStrategy.SignUpSecurityKey, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.Settings, [NodeGroup.Default, NodeGroup.Profile]],
    [FlowStrategy.Password, [NodeGroup.Default, NodeGroup.Password]],
    [
      FlowStrategy.NotificationPreferencesSms,
      [NodeGroup.Default, NodeGroup.NotificationPreferencesSms],
    ],
    [
      FlowStrategy.NotificationPreferencesEmail,
      [NodeGroup.Default, NodeGroup.NotificationPreferencesEmail],
    ],
    [
      FlowStrategy.NotificationPreferencesPush,
      [NodeGroup.Default, NodeGroup.NotificationPreferencesPush],
    ],
    [FlowStrategy.LookupSecret, [NodeGroup.Default, NodeGroup.LookupSecret]],
    [FlowStrategy.Webauthn, [NodeGroup.Default, NodeGroup.Webauthn]],
    [FlowStrategy.Passkey, [NodeGroup.Default, NodeGroup.Passkey]],
    [FlowStrategy.Totp, [NodeGroup.Default, NodeGroup.Totp]],
    [FlowStrategy.Mfa, [NodeGroup.Default, NodeGroup.Mfa]],
    [FlowStrategy.DeleteMe, [NodeGroup.Default, NodeGroup.DeleteMe]],
  ]);

  export const FLOW_STRATEGY_MAP = new Map([
    [
      FlowStrategy.SignIn,
      {
        [FlowMethod.Password]: FlowStrategy.SignIn,
        [FlowMethod.Webauthn]: FlowStrategy.SignInSecurityKey,
        [FlowMethod.QRCode]: FlowStrategy.SignInQRCode,
        [FlowMethod.Passkey]: FlowStrategy.SignInPasskey,
      },
    ],
    [
      FlowStrategy.SignUp,
      {
        [FlowMethod.Password]: FlowStrategy.SignUp,
        [FlowMethod.Webauthn]: FlowStrategy.SignUpSecurityKey,
        [FlowMethod.QRCode]: FlowStrategy.SignInQRCode,
      },
    ],
  ]);

  export const MESSAGE_TITLE_RECOVERY_MAP = new Map([
    [ServerMessageType.Error, 'Recovery Code Invalid!'],
    [ServerMessageType.Info, 'Recovery Code'],
    [ServerMessageType.Success, 'Congratulations!'],
    [ServerMessageType.Default, 'Enter your Username!'],
  ]);

  export const MESSAGE_TITLE_VERIFICATION_MAP = new Map([
    [ServerMessageType.Error, 'Verification Code Invalid!'],
    [ServerMessageType.Info, 'Verification Code'],
    [ServerMessageType.Success, 'Congratulations!'],
    [ServerMessageType.Default, 'Enter your Username!'],
  ]);

  export const MESSAGES_MAP = new Map<number, string>([
    [
      4000006,
      'The provided credentials are invalid, check for spelling mistakes in your password or username.',
    ],
    [
      4000007,
      'That username is already taken. How about trying something unique?',
    ],
    [4010001, 'Your sign in session has been expired. Please sign in again.'],
    [4040001, 'Your sign up session has been expired. Please sign up again.'],
    [
      1060001,
      'You successfully recovered your account. Please change your password or set up an alternative login method (e.g. Security Key or Passkey) within the next %d minutes.',
    ],
  ]);

  export const ENUM_FIELD_VALUES = new Map<string, Map<number, string>>([
    [
      Client.MaritalStatusId,
      new Map<number, string>([
        [0, 'Unknown'],
        [1, 'Single'],
        [2, 'Married'],
        [3, 'Widowed'],
        [4, 'Divorced'],
      ]),
    ],
    [
      Client.DocumentType,
      new Map<number, string>([
        [0, 'Unknown'],
        [1, 'NationalId'],
        [2, 'DiplomaticPassport'],
        [3, 'ForeignPassport'],
        [4, 'TravelPassport'],
        [5, 'DriversLicense'],
        [6, 'BirthCertificate'],
        [7, 'ResidencePermit'],
        [8, 'TemporaryPermit'],
      ]),
    ],
    [
      Client.Gender,
      new Map<number, string>([
        [0, 'Unspecified'],
        [1, 'Male'],
        [2, 'Female'],
      ]),
    ],
  ]);

  export const SETTINGS_PROFILE_PRIORITY_MAP = new Map([
    [Client.Username, 0],
    [Client.FirstName, 1],
    [Client.LastName, 2],
    [Client.Gender, 3],
    [Client.BirthDay, 4],
    [Client.MaritalStatusId, 5],
    [Client.DocumentType, 6],
  ]);

  export const MFA_PRIORITY_MAP = new Map([
    [Client.MobileId, 0],
    [Client.Push, 1],
    [Client.Sms, 2],
    [Client.Email, 3],
  ]);

  export const SIGN_UP_PRIORITY_MAP = new Map([
    [Client.Username, 1],
    [Client.Email, 2],
    [Client.Tabs, 3],
    [Client.Password, 4],
    [Client.WebAuthRegisterDisplayName, 5],
    [Client.Method, 6],
  ]);

  export const EMAIL_NOTIFICATION_PRIORITY_MAP = new Map([
    [Client.Enable, 1],
    [Client.Address, 2],
    [Client.NpNewAddress, 3],
    [Client.Method, 4],
  ]);

  export const SIGN_IN_PRIORITY_MAP = new Map([
    [Client.Identifier, 1],
    [Client.Tabs, 2],
    [Client.Password, 3],
    [Client.Method, 4],
    [Client.PasskeyLoginTrigger, 5],
    [Client.Divider, 6],
  ]);

  export const FLOW_NODES_SIGN_UP = new Map([
    [
      FlowStrategy.SignUp,
      {
        [NodeAction.Remove]: [
          Client.WebAuthRegisterDisplayName,
          Client.WebAuthRegister,
          Client.WebAuthRegisterTrigger,
          Client.PasskeyCreateData,
          Client.PasskeyRegister,
          Client.PasskeyRegisterTrigger,
          Client.Script,
        ],
        [NodeAction.Add]: [Client.Password, Client.Method],
      },
    ],
    [
      FlowStrategy.SignUpSecurityKey,
      {
        [NodeAction.Remove]: [Client.Password, Client.Method],
        [NodeAction.Add]: [
          Client.WebAuthRegisterDisplayName,
          Client.WebAuthRegister,
          Client.WebAuthRegisterTrigger,
          Client.Script,
        ],
      },
    ],
    [
      FlowStrategy.SignUpPasskey,
      {
        [NodeAction.Remove]: [
          Client.Password,
          Client.WebAuthRegisterDisplayName,
          Client.WebAuthRegister,
          Client.WebAuthRegisterTrigger,
          Client.Script,
          Client.Method,
        ],
        [NodeAction.Add]: [
          Client.PasskeyCreateData,
          Client.PasskeyRegister,
          Client.PasskeyRegisterTrigger,
          Client.Script,
        ],
      },
    ],
  ]);

  export const FLOW_NODES_SIGN_IN = new Map([
    [
      FlowStrategy.SignIn,
      {
        [NodeAction.Remove]: [
          Client.PasskeyLoginTrigger,
          Client.PasskeyLogin,
          Client.PasskeyChallenge,
        ],
        [NodeAction.Add]: [Client.Password],
      },
    ],
    [
      FlowStrategy.SignInSecurityKey,
      {
        [NodeAction.Remove]: [
          Client.Password,
          Client.Method,
          Client.PasskeyLoginTrigger,
          Client.PasskeyLogin,
          Client.PasskeyChallenge,
        ],
        [NodeAction.Add]: [Client.Method],
      },
    ],
    [
      FlowStrategy.SignInPasskey,
      {
        [NodeAction.Remove]: [Client.Password, Client.Method],
        [NodeAction.Add]: [
          Client.PasskeyLoginTrigger,
          Client.PasskeyLogin,
          Client.PasskeyChallenge,
        ],
      },
    ],
  ]);

  export const FLOW_SIGN_IN_TABS_MAP = new Map([
    [AuthenticationTab.Password, FlowStrategy.SignIn],
    [AuthenticationTab.SecurityKey, FlowStrategy.SignInSecurityKey],
    [AuthenticationTab.Passkey, FlowStrategy.SignInPasskey],
  ]);

  export const FLOW_SIGN_UP_TABS_MAP = new Map([
    [AuthenticationTab.Password, FlowStrategy.SignUp],
    [AuthenticationTab.SecurityKey, FlowStrategy.SignUpSecurityKey],
    [AuthenticationTab.Passkey, FlowStrategy.SignUpPasskey],
  ]);

  export const DIVIDER_NODE = {
    attributes: {
      name: InputType.DIVIDER,
      disabled: false,
      node_type: InputType.DIVIDER,
      type: InputType.DIVIDER,
    },
    meta: {
      label: {
        text: InputType.DIVIDER,
        type: InputType.DIVIDER,
      },
    },
    group: NodeGroup.Default,
    messages: [],
    type: InputType.DIVIDER,
  };

  export const TABS_NODE = {
    attributes: {
      name: InputType.TABS,
      disabled: false,
      node_type: InputType.TABS,
      type: InputType.TABS,
    },
    meta: {
      label: {
        text: InputType.TABS,
        type: InputType.TABS,
      },
    },
    tabs: [
      {
        name: AuthenticationTab.Password,
        active: true,
      },
      {
        name: AuthenticationTab.SecurityKey,
        active: false,
      },
      {
        name: AuthenticationTab.Passkey,
        active: false,
      },
    ],
    group: NodeGroup.Default,
    messages: [],
    type: InputType.TABS,
  };
}

import { FC, memo, useState, useCallback } from 'react';

import { IconSize, InputType, TooltipDirection } from 'enum';
import { InputElementBase } from '../../models/input-element-base';
import { Error, Icon, Tooltip } from 'components';
import { useBreakpoint } from '../../hooks';

import styles from './Input.module.scss';

export const Input: FC<InputElementBase> = memo(
  ({
    wrapperClassname,
    className,
    error,
    disabled,
    icon,
    iconName,
    iconColor,
    alignIcon,
    iconSize,
    iconClassName,
    tooltip,
    tooltipMessage,
    tooltipDirection,
    tooltipDelay,
    label,
    labelClass,
    name,
    onChange,
    placeholder,
    readOnly,
    required,
    type,
    pattern,
    title,
    value = '',
    autoCapitalize,
  }) => {
    const isMobile = useBreakpoint(500);
    const [revealPassword, setRevealPassword] = useState<boolean>(false);
    tooltipDirection =
      tooltipDirection ||
      (isMobile ? TooltipDirection.AUTO : TooltipDirection.RIGHT);

    const onReavealPassword = useCallback(() => {
      setRevealPassword(!revealPassword);
    }, [revealPassword]);

    return (
      <div
        className={`${wrapperClassname} ${
          type === InputType.HIDDEN ? 'd-none' : ''
        } clearfix`}
      >
        <div className={`focus-label ${error ? 'error' : ''}`}>
          {label && (
            <label
              htmlFor={name}
              className={`form-label ${labelClass ?? ''} ${
                required ? 'required' : ''
              }`}
            >
              {label}
            </label>
          )}
          {tooltip && (
            <Tooltip
              content={tooltipMessage}
              delay={tooltipDelay}
              direction={tooltipDirection}
              className={styles.inputTooltip}
            >
              <Icon size={IconSize.SIZE_12} name="icon-dgpass-info" />
            </Tooltip>
          )}
          <div className={styles.controlInner}>
            {icon && (
              <Icon
                size={iconSize}
                name={iconName || 'icon-dgpass-password'}
                color={iconColor}
                className={iconClassName}
                align={alignIcon}
              />
            )}
            <input
              id={name}
              className={`form-control ${className}`}
              aria-label={name}
              data-testid={name}
              tabIndex={0}
              type={
                type !== InputType.PASSWORD
                  ? type
                  : revealPassword
                  ? InputType.TEXT
                  : type
              }
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              readOnly={readOnly}
              required={required}
              pattern={pattern}
              title={title}
              autoCapitalize={autoCapitalize}
            />
            {type === InputType.PASSWORD && value.length > 0 && (
              <Icon
                size={IconSize.SIZE_13}
                name={
                  revealPassword
                    ? 'icon-dgpass-eye-text'
                    : 'icon-dgpass-eye-password'
                }
                color={iconColor}
                className={`${iconClassName} ${styles.eye} cursor-pointer float-end`}
                onClick={onReavealPassword}
              />
            )}
          </div>
          {error && <Error message={error} />}
        </div>
      </div>
    );
  },
);

import { FC, memo, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';

import passKey from '../../assets/images/pass-key.svg';
import googlePlay from '../../assets/images/google.svg';
import applePlay from '../../assets/images/apple.svg';
import mobileLogo from '../../assets/images/mobile_logo.svg';

import { Button } from 'components';
import { ButtonStyle, ButtonType } from 'enum';
import { routes } from '../../models/routes';
import { AuthContext } from '../../store/AuthContext';

import styles from './Home.module.scss';

export const Home: FC = memo(() => {
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <section className={`${styles.mainBg} d-flex align-items-center`}>
      <div className="container d-flex align-items-center justify-content-center">
        <div className={`${styles.content} rounded my-5 card-max-width`}>
          <div className="p-5 text-center">
            <img className="mx-auto" src={passKey} alt="Pass key" />
            <p className="py-4 fs-5 px-4 lh-base text-white">
              National Digital Identity and Authentication Service
            </p>
            {!isLoggedIn && (
              <Button
                key="Login"
                name="Login"
                className={`${ButtonStyle.PRIMARY} fs-5 py-3 w-100`}
                type={ButtonType.BUTTON}
                onClick={() => navigate(routes.login)}
              >
                Get Started
              </Button>
            )}
            <p
              className={`${styles.subTitle} ${
                isLoggedIn ? 'mt-0' : ''
              } fs-6 mb-2`}
            >
              To Sign Up get your MobileID digital identity
            </p>
            <div className="d-flex justify-content-between gap-1 w-100 mt-3 flex-sm-row flex-column">
              <div>
                <div className="mb-2">
                  <Link to={window.REACT_APP_IOS_STORE_URL}>
                    <img
                      className="mx-auto"
                      src={applePlay}
                      alt="Apple Store"
                    />
                  </Link>
                </div>
                <div>
                  <Link to={window.REACT_APP_GOOGLE_STORE_URL} className="mb-1">
                    <img
                      className="mx-auto"
                      src={googlePlay}
                      alt="Google Play"
                    />
                  </Link>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-grow-1 text-white">
                or
              </div>
              <div>
                <div
                  style={{ height: 'auto', margin: '0 auto', width: '100%' }}
                >
                  <QRCode
                    size={95}
                    quietZone={5}
                    value={window.REACT_APP_DYNAMIC_STORE_URL}
                    logoImage={mobileLogo}
                    removeQrCodeBehindLogo={true}
                    ecLevel="H"
                    qrStyle="dots"
                    logoWidth={30}
                    id="dynamic-store-qr-code"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center justify-content-sm-end align-items-center">
              <p className={styles.qrText}>Scan to download the app</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
